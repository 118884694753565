import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Campaign } from './../../../shared/campaign.model';
import { CampaignService } from './../../../shared/campaign.service';
import { FileService } from './../../../shared/file.service';
import { KioskService } from './../../../shared/kiosk.service';
import { PagerService } from '../../../shared/pager.service';
import { ignoreElements } from 'rxjs/operators';

@Component({
  selector: 'app-updatecampaign',
  templateUrl: './updatecampaign.component.html',
  styleUrls: ['./updatecampaign.component.css',
  '../createcampaign/bootstrap.css',
  '../createcampaign/bs-switches.css']
})
export class UpdatecampaignComponent implements OnInit {

  form: FormGroup;
  fb: FormBuilder;
  campaign: Campaign;
  validMsg;
  errorMsg;
  isIconNotSelected;
  disabled: boolean;
  disabledUpdate : boolean;

  listDeviceData = [];
  kioskArray = [];
  pager: any = {};
  pagedItems: any[];
  private allItems: any[];
  listDevice: Boolean = false;
  listKiosk: Boolean = false;
  listKioskSearch: Boolean = false;
  listKioskEmpty: Boolean = false;
  listIcon = [];
  urlpath : String = "";
  constructor(fb: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private campaignService: CampaignService, private pagerService: PagerService, private kioskService: KioskService,private fileService : FileService) {
    this.urlpath = "https://appdev.e6kdonation.com/files/icones/";

    this.activatedRoute.queryParams.subscribe(params => {

      this.campaign = new Campaign();
      this.campaign._id = params['_id'];

      this.readCampaign(this.campaign._id, this.campaign);
     // this.initListIcon();
      this.readListIconAvailable();
    });

    
   

    
    this.form = fb.group({
      _id: [''],
      icon: [''],
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  readListIconAvailable(){
    this.fileService.getIconListFiles().subscribe(res => {
      if(res !== undefined && res !== null ){
        this.initListIconFromServer(res);
      }     
    });
  }

  initListIconFromServer(res){
    var urlPath = this.urlpath;
    var iconname = "iconXX.png";
    const regex = /XX/gi;
    res.data.forEach(element => {
      var name = urlPath + element.name;
      var obj = {
        id : element.name.substring(0, 6),
        icon : element.name,
        iconpath : element.urlpath
      }
      this.listIcon.push(obj);
      
    });  
    
  }
  initListIcon(){
    var urlPath = this.urlpath;
    var iconname = "iconXX.png";
    const regex = /XX/gi;
    for(var i = 1 ; i<23 ;i++){
      
        var icon = iconname.replace(regex,i.toString().padStart(2,'0'));
        var name = urlPath + icon;
        var obj = {
          id : icon.substring(0, 6),
          icon : icon,
          iconpath : name
        }
        this.listIcon.push(obj);
      
    }
    
  }


  ngOnInit() {
    this.disabled = false;
    this.disabledUpdate = false;
    //this.setForm(this.campaign);
    // this.form.controls['_id'].setValue(this.campaign._id);
    // this.form.controls['title'].setValue(this.campaign.title);
    // this.form.controls['description'].setValue(this.campaign.description);
  }

  setForm(campaign) {
    this.form.controls['_id'].setValue(this.campaign._id);
    this.form.controls['title'].setValue(this.campaign.title);
    this.form.controls['description'].setValue(this.campaign.description);

    this.SetControlEnabled(this.form.controls['title'], this.campaign.editable);
    this.SetControlEnabled(this.form.controls['description'], this.campaign.editable);
    this.disabled = !this.campaign.editable;
    this.disabledUpdate = !this.campaign.editable;
  }

  SetControlEnabled(control, flag) {
    control.disable();
    if (flag)
      control.enable();
  }

  readCampaign(sId, campaign) {
    this.campaignService.getCampaignById(sId).subscribe(res => {
      this.campaign._id = sId;
      this.campaign.idCampaign = res['campaign'].idCampaign;
      this.campaign.title = res['campaign'].title;
      this.campaign.description = res['campaign'].description;
      this.campaign.icon = res['campaign'].icon;
      this.campaign.actif = res['campaign'].actif;
      this.campaign.editable = res['campaign'].editable;
      this.campaign.idOrganization = res['campaign'].idOrganization;
      this.campaign.idDeviceList = res['campaign'].idDeviceList;
      this.campaign.idKioskList = res['campaign'].idKioskList;
      this.getlistDevice(this.campaign.idOrganization);
      this.setForm(this.campaign);
      return this.campaign;

    });
  }
  addIconCampaign(icon) {
    this.campaign.icon = icon;
  }

  getFormSubmit() {
    var objetUpdate = {};

    objetUpdate["idOrganization"] = this.campaign.idOrganization;
    objetUpdate["_id"] = this.campaign._id;
    objetUpdate["idCampaign"] = this.campaign.idCampaign;
    
    if(this.campaign.editable === false){      
      objetUpdate['idKioskList'] = this.getActiveKiosk();
    }else{
      objetUpdate['title'] = this.form.value.title;
      objetUpdate['description'] = this.form.value.description;      
      objetUpdate['icon'] = this.campaign.icon;
      objetUpdate['idKioskList'] = this.getActiveKiosk();
    }
    return objetUpdate;
  }

  onSubmit() {
    var objetUpdate = this.getFormSubmit();
 
    if (this.form.status === 'VALID') {
      this.campaignService.update(objetUpdate).subscribe(
        res => {
          this.validMsg = true;
          setTimeout(() => {
            this.router.navigateByUrl('/dashboard/campaign');
          }, 2000);
        },
        err => {
          this.errorMsg = true;
          setTimeout(() => {
            this.errorMsg = false;
          }, 2000);
        }
      );
    } else {


    }
  }

  getActiveKiosk(){
    var idKioskList = [];
    var ALL = true;
    this.allItems.forEach(element => {
      if (element.isActive == true){
        idKioskList.push(element.idKiosk);
      }else{
        ALL = false;
      }
    });
    if (ALL == true){
     // return "ALL";
    }    
    return idKioskList;
  }

  getlistDevice(id) {
    var that = this;
    this.kioskService.getKioskOrganisation(id).subscribe(
      res => {
        console.log(res);

        var listData = res['kiosks'];
        listData = this.activeAllKiosk(listData);

        this.listDeviceData = listData;
        this.kioskArray = listData;
        this.allItems = listData;

        this.listDevice

        if (this.allItems.length !== 0) {
          this.listDevice = true;
          this.listKiosk = true;
          this.listKioskSearch = true;
          this.listKioskEmpty = false;
        } else {
          this.listKioskEmpty = true;
          this.listDevice = false;
          this.listKiosk = false;
          this.listKioskSearch = false;
        }
        this.setPage(1);

      },
      err => {
        console.log(err);
      }
    );
  }

  switchKiosk(event){

    console.log(event);
    this.disabledUpdate = false;
    this.SetControlEnabled(this.form.controls['title'], this.campaign.editable);
  }


  activeAllKiosk(aListKiosk) {
    if (this.campaign.idKioskList !== undefined && this.campaign.idKioskList !== null) {
      var list : string[] = this.campaign.idKioskList;

      if (aListKiosk != undefined && aListKiosk !== null && Array.isArray(aListKiosk)) {
        aListKiosk.forEach(element => {
          var idKiosk : string = element['_id'];
          if (list.indexOf(idKiosk) >-1){

            element.isActive = true;
          }else{
            element.isActive = false;
          }
           
        });
      }
    }
    return aListKiosk;
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);

  }

  searchKiosk(searchValue: string) {
    const kioskArray = this.kioskArray.filter(x => x._id.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = kioskArray;
    if (this.allItems.length > 0) {
      this.listKiosk = true;
      this.listKioskSearch = true;
      this.listKioskEmpty = false;
    } else {
      this.listKioskSearch = false;
      this.listKiosk = true;
      this.listKioskEmpty = true;
    }
    this.setPage(1);
  }


  changeTitle(searchValue: string) {
    this.campaign.title = searchValue;
  }
}
