// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //apiBaseUrl: 'http://localhost:3002/api',
  //apiBaseUrl2: 'http://localhost:3002/apikpi'
  //apiBaseUrl: 'http://46.101.202.225:3002/api'
  //  apiBaseUrl: '/api'
  apiBaseUrl: '/api',
  apiBaseUrl2: '/apikpi',
  apiBaseUrl3: '/files'

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
